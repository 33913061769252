@import url(https://fonts.googleapis.com/earlyaccess/lateef.css);



  #section-to-print {
    color: pink;
    background: #AAAAAA;
  }

#sideBarBGC{
    background-image: url("./sweet.jpg"); 
    height: 100vh; 
    width: 239px;
    background-repeat: no-repeat;
    background-size: cover;  
    color:#fafafa;
}
#sideBarBGC1{
    position: absolute;
    width: 239px;
  
    background-color: #000000;  
    height: 100vh; 
    opacity: 0.4;
    background-repeat: no-repeat;
    background-size: cover;  
}
.maincardDashContiner{
    width: 245px;
    height: 165px;
    background-color: #fafafa;
 
    display: flex;
align-items: center;
justify-content: center;
}
.cardDashContiner{
    width: 219px;
    height: 115px;
    background-color: #fafafa;
}
.cardDashContiner1{
    width: 219px;
    height: 115px;
    color: rgba(0, 0, 0, 0.87);
    border: 0;
    background: white;
    border-radius: 6px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(255, 152, 0,.4);
}
 #titleCardAdmin{
     text-align: center;
     padding-top: 20px;
     font-size: 19px;
     font-weight: bold;
     color: #000;
 } 
 #ContentCardAdmin{
    text-align: center;
    padding-top: 20px;
    font-size: 20px;
    font-weight: bold;
    color: #000;
 }

.cardDashContiner2{
    width: 65px;
    height: 65px;
    background: linear-gradient(60deg, #ffa726, #fb8c00);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(255, 152, 0,.4);
    float: left;
    margin-left: 10px;
    margin-right: 15px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cardDashContinertext{
   
    color: black;
margin-left: 10px;
   margin-top: 10px;
}
#rowDash{
    display: flex;
    align-items: center;
    justify-content: center;
}
#colorbtn{
    margin-top: 20px;
    color: #ffffff;
}
.tabActive{ 
float: left;
box-shadow:         inset 0 0 30px #ffa726;
width: 100%;
  }
 #fonTable{
     font-size: 20px;
     color: #000000;
     font-weight: 600;
     text-align: right;
 }
#date{
    margin:0px 10px 10px 10px;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 600;
}
#titleTable{
    height: 80px;
    width: 100%;
    background: linear-gradient(-70deg, #ffc107 30%, rgba(216, 200, 200, 0) 30%), url('story.jpg');
    box-shadow:         inset 0 0 30px #ffa726;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: white;
    font-size: 28px;
    font-weight: bold;
    border-radius: 5px;
    margin-top: 50px;
}
#titleTable span{
    margin-right: 50px
}
#AddCateContiner{

    width: 100%;
    direction: rtl;

}
#AddCatetitle{
    width: 20%;
    display: flex;
    justify-content: flex-end;
}
#AddCateInput{
    direction: rtl;
    
}
#selectCategoryContiner{
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
 
}
#selectCategoryTitle{
    width: 20%;
    display: flex;
    justify-content: flex-end;
}
#selectCategoryCInput{
    display: flex;
/* text-align: right; */
   flex-direction: row-reverse;
    margin-right: 2%;
    width: 98%;
}
#RadioContiner{
    display: flex;
       flex-direction: row-reverse;
       margin-right: 2%;
       margin-top: 30px;   
}
#CheckContiner{
    display: flex;
    align-items: center;
    cursor: pointer;
}
#CheckContiner1{
    display: flex;
    align-items: center;
    margin-right: 50px;
    cursor: pointer;
}
#CheckContiner span,#CheckContiner1 span{
    margin-right: 5px;
}
#logoHeader{
    width: 100%;
    display: flex;
  align-items: center;
  justify-content: center;
    height: 80px;
}
#logoHeaderimg{
    height: 70px;
    float:right;
}
#msgDelete{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
}
#AddRootNameInput{
    width: 100%;
    height: 40px;
    font-size: 18px;
    font-weight: 400;
    text-align: right;
    margin-bottom: 20px;
}
#sectionAddsection{
    display: flex;
    flex-direction: column;
    direction: rtl;

}
#Piker{
    margin-top: 40px;
    padding-right: 20px;
    width: 40px;
    /* display: block; */
    direction: rtl;
    border-radius: 25%;
    /* justify-content: flex-end; */
}
#printFOnt{
    font-size: 20px;
    font-weight: 600;
   text-align: right;
   float: right;
   direction: rtl;
}
/* @media screen and (max-width: 600px) {


} */