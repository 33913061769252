#logoHomeimg {
  height: 60px;
  float: left;
}

#BtNHome {
  color: azure;
  font-size: 20px;
  font-weight: 600;
}

#ContinerComponentHome {
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
}

#Component1Home {
  height: 300px;
  width: 300px;
  /* background: linear-gradient(60deg, #ffa726, #af6b17); */
  border-radius: 20px;
  margin-right: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(255, 152, 0, .4);
}

#Component2Home {
  height: 300px;
  width: 300px;
  background: linear-gradient(60deg, #ffa726, #af6b17);
  border-radius: 20px;
  margin-left: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(255, 152, 0, .4);
}

#Component1Homeimg, #Component2Homeimg {
  height: 300px;
  width: 300px;
  border-radius: 20px;
}

#Component1Home:hover, #Component2Home:hover {
  transform: scale(1.05)
}

#title {
  text-align: center;
  font-family: arial, sans-serif;
  border: solid 2px #000;
}

#students {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 2px solid #000;
  width: 100%;
  direction: rtl;
  font-size: 22px;
  color: #000;
  font-weight: bold;
}

#students td, #students th {
  border: 2px solid #000;
  padding: 2px;
}

#students tr:nth-child(even) {
  background-color: #f2f2f2;
}

#students tr:hover {
  background-color: #ddd;
}

#students th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #ffa726;
  color: black;
}

#RootNAmeprint {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  border: #000 solid 2px;
  border-bottom-width: 0px;
  font-size: 18px;
  color: #000;
  font-weight: 500;
  /* direction: rtl; */
  text-align: right;
  padding: 5px;
}

#studentsRoot {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 2px solid rgb(110, 107, 107);
  width: 100%;
  direction: rtl;
  font-size: 22px;
  color: #000;
  font-weight: bold;
}

#studentsRoot td, #studentsRoot th {
  border: 2px solid rgb(110, 107, 107);
  padding: 2px;
}

#SectionTable {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  border: #000 solid 2px;
  border-bottom-width: 0px;
  font-size: 22px;
  color: #000;
  font-weight: bold;
  direction: rtl;
  text-align: center;
  padding: 5px;
}

#notsAllOrder {
  display: flex;
  align-items: center;
  min-height: 60px;
  max-height: auto;
  width: 100%;
  border: #000 solid 2px;
  /* border-top-width: 0px; */
  font-size: 22px;
  color: #000;
  font-weight: 500;
  direction: rtl;
  text-align: right;
  padding: 5px;
}

#tablePrintMargin {
  margin-bottom: 100px;
}

#SliderEverGreen {
  background-color: #ffffff;
  min-height: 100vh;
  max-height: 100%;
  width: 100%;
  margin-top: 82px;
}
#loading {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color:#eeeeee;
  background-image: url("./loading.gif");
  background-repeat: no-repeat;
  background-position: center;
}
@media print {
  body * {
    /* visibility: hidden; */
    margin-top: -8px;
    /* padding-top: 0px; */
  }
  /* #students ,#notsAllOrder,#notsAllOrder * , #students * {
      visibility: visible;
    }
  
     #students ,#notsAllOrder {
      position: relative;
      left: 0;
      top: -10px;
    } */
}

@media screen and (max-width: 768px) {
  #ContinerComponentHome {
    flex-direction: column;
  }
  #Component1Home {
    margin-right: 0px;
    margin-top: 20px;
    height: 200px;
    width: 200px;
  }
  #Component2Home {
    margin-left: 0px;
    margin-top: 20px;
    height: 200px;
    width: 200px;
  }
  #Component1Homeimg, #Component2Homeimg {
    height: 130px;
    width: 130px;
  }
}