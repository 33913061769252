#mainLoginContiner {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(60deg, #0f0e0d, #0a0701);
    background-image: url(./rafil.png);
    background-repeat: no-repeat;
    background-size: cover;

}

#RowLoginContiner {
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

#Coool1 {
    margin: 0px;
    padding: 0px;
    height: 500px;
}

#Coool2 {
    height: 500px;
    padding: 0px;
    margin: 0px 0px;
}

#SideOneContiner {
    /* background: linear-gradient(-70deg, #ffc107 30%,#02020 */
    background: linear-gradient(60deg, #ffa726, #af6b17);
    height: 500px;
    border-radius: 5px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(255, 152, 0,.4);
    display: flex;
    align-items: center;
    justify-content: center;
}

#logologinrimg{
height: 120px;
}
#SideTwoContiner {
    background: linear-gradient(60deg, #f0eeeb, #bbae9e);
    height: 500px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(255, 152, 0,.4);

}
#SINgnINTItle{
    margin-top: 20%;
    font-size: 40px;
    font-weight: 600;
}
#FOrmS {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20%;
}
#NAMlogIN{
text-align: right;
height: 40px;
font-size: 18px;
font-weight: 600;
}
#PWSlogIN{
    margin: 30px 0px;
    height: 40px;
    text-align: right;
    font-size: 18px;
    font-weight: 600;
}
@media screen and (max-width: 768px) {
    #SideOneContiner,#Coool1{
        display: none;
    }
    #Coool2 {
        padding: 0px 50px;
    }
    #SideTwoContiner {
        background-color: blueviolet;
        height: 500px;
    }
}