@import url(https://fonts.googleapis.com/earlyaccess/lateef.css);
body {
  margin: 0;
  padding: 0;
  background-color: #eeeeee;
}
a ,a:hover{
  text-decoration: none;
  color: black;
 
}
#mainLoginContiner {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(60deg, #0f0e0d, #0a0701);
    background-image: url(/static/media/rafil.cfc90cb3.png);
    background-repeat: no-repeat;
    background-size: cover;

}

#RowLoginContiner {
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

#Coool1 {
    margin: 0px;
    padding: 0px;
    height: 500px;
}

#Coool2 {
    height: 500px;
    padding: 0px;
    margin: 0px 0px;
}

#SideOneContiner {
    /* background: linear-gradient(-70deg, #ffc107 30%,#02020 */
    background: linear-gradient(60deg, #ffa726, #af6b17);
    height: 500px;
    border-radius: 5px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(255, 152, 0,.4);
    display: flex;
    align-items: center;
    justify-content: center;
}

#logologinrimg{
height: 120px;
}
#SideTwoContiner {
    background: linear-gradient(60deg, #f0eeeb, #bbae9e);
    height: 500px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(255, 152, 0,.4);

}
#SINgnINTItle{
    margin-top: 20%;
    font-size: 40px;
    font-weight: 600;
}
#FOrmS {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20%;
}
#NAMlogIN{
text-align: right;
height: 40px;
font-size: 18px;
font-weight: 600;
}
#PWSlogIN{
    margin: 30px 0px;
    height: 40px;
    text-align: right;
    font-size: 18px;
    font-weight: 600;
}
@media screen and (max-width: 768px) {
    #SideOneContiner,#Coool1{
        display: none;
    }
    #Coool2 {
        padding: 0px 50px;
    }
    #SideTwoContiner {
        background-color: blueviolet;
        height: 500px;
    }
}
#section-to-print {
    color: pink;
    background: #AAAAAA;
  }

#sideBarBGC{
    background-image: url(/static/media/sweet.e9d634d3.jpg); 
    height: 100vh; 
    width: 239px;
    background-repeat: no-repeat;
    background-size: cover;  
    color:#fafafa;
}
#sideBarBGC1{
    position: absolute;
    width: 239px;
  
    background-color: #000000;  
    height: 100vh; 
    opacity: 0.4;
    background-repeat: no-repeat;
    background-size: cover;  
}
.maincardDashContiner{
    width: 245px;
    height: 165px;
    background-color: #fafafa;
 
    display: flex;
align-items: center;
justify-content: center;
}
.cardDashContiner{
    width: 219px;
    height: 115px;
    background-color: #fafafa;
}
.cardDashContiner1{
    width: 219px;
    height: 115px;
    color: rgba(0, 0, 0, 0.87);
    border: 0;
    background: white;
    border-radius: 6px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(255, 152, 0,.4);
}
 #titleCardAdmin{
     text-align: center;
     padding-top: 20px;
     font-size: 19px;
     font-weight: bold;
     color: #000;
 } 
 #ContentCardAdmin{
    text-align: center;
    padding-top: 20px;
    font-size: 20px;
    font-weight: bold;
    color: #000;
 }

.cardDashContiner2{
    width: 65px;
    height: 65px;
    background: linear-gradient(60deg, #ffa726, #fb8c00);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(255, 152, 0,.4);
    float: left;
    margin-left: 10px;
    margin-right: 15px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cardDashContinertext{
   
    color: black;
margin-left: 10px;
   margin-top: 10px;
}
#rowDash{
    display: flex;
    align-items: center;
    justify-content: center;
}
#colorbtn{
    margin-top: 20px;
    color: #ffffff;
}
.tabActive{ 
float: left;
box-shadow:         inset 0 0 30px #ffa726;
width: 100%;
  }
 #fonTable{
     font-size: 20px;
     color: #000000;
     font-weight: 600;
     text-align: right;
 }
#date{
    margin:0px 10px 10px 10px;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 600;
}
#titleTable{
    height: 80px;
    width: 100%;
    background: linear-gradient(-70deg, #ffc107 30%, rgba(216, 200, 200, 0) 30%), url(/static/media/story.add42303.jpg);
    box-shadow:         inset 0 0 30px #ffa726;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: white;
    font-size: 28px;
    font-weight: bold;
    border-radius: 5px;
    margin-top: 50px;
}
#titleTable span{
    margin-right: 50px
}
#AddCateContiner{

    width: 100%;
    direction: rtl;

}
#AddCatetitle{
    width: 20%;
    display: flex;
    justify-content: flex-end;
}
#AddCateInput{
    direction: rtl;
    
}
#selectCategoryContiner{
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
 
}
#selectCategoryTitle{
    width: 20%;
    display: flex;
    justify-content: flex-end;
}
#selectCategoryCInput{
    display: flex;
/* text-align: right; */
   flex-direction: row-reverse;
    margin-right: 2%;
    width: 98%;
}
#RadioContiner{
    display: flex;
       flex-direction: row-reverse;
       margin-right: 2%;
       margin-top: 30px;   
}
#CheckContiner{
    display: flex;
    align-items: center;
    cursor: pointer;
}
#CheckContiner1{
    display: flex;
    align-items: center;
    margin-right: 50px;
    cursor: pointer;
}
#CheckContiner span,#CheckContiner1 span{
    margin-right: 5px;
}
#logoHeader{
    width: 100%;
    display: flex;
  align-items: center;
  justify-content: center;
    height: 80px;
}
#logoHeaderimg{
    height: 70px;
    float:right;
}
#msgDelete{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
}
#AddRootNameInput{
    width: 100%;
    height: 40px;
    font-size: 18px;
    font-weight: 400;
    text-align: right;
    margin-bottom: 20px;
}
#sectionAddsection{
    display: flex;
    flex-direction: column;
    direction: rtl;

}
#Piker{
    margin-top: 40px;
    padding-right: 20px;
    width: 40px;
    /* display: block; */
    direction: rtl;
    border-radius: 25%;
    /* justify-content: flex-end; */
}
#printFOnt{
    font-size: 20px;
    font-weight: 600;
   text-align: right;
   float: right;
   direction: rtl;
}
/* @media screen and (max-width: 600px) {


} */
#logoHomeimg {
  height: 60px;
  float: left;
}

#BtNHome {
  color: azure;
  font-size: 20px;
  font-weight: 600;
}

#ContinerComponentHome {
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
}

#Component1Home {
  height: 300px;
  width: 300px;
  /* background: linear-gradient(60deg, #ffa726, #af6b17); */
  border-radius: 20px;
  margin-right: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(255, 152, 0, .4);
}

#Component2Home {
  height: 300px;
  width: 300px;
  background: linear-gradient(60deg, #ffa726, #af6b17);
  border-radius: 20px;
  margin-left: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(255, 152, 0, .4);
}

#Component1Homeimg, #Component2Homeimg {
  height: 300px;
  width: 300px;
  border-radius: 20px;
}

#Component1Home:hover, #Component2Home:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05)
}

#title {
  text-align: center;
  font-family: arial, sans-serif;
  border: solid 2px #000;
}

#students {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 2px solid #000;
  width: 100%;
  direction: rtl;
  font-size: 22px;
  color: #000;
  font-weight: bold;
}

#students td, #students th {
  border: 2px solid #000;
  padding: 2px;
}

#students tr:nth-child(even) {
  background-color: #f2f2f2;
}

#students tr:hover {
  background-color: #ddd;
}

#students th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #ffa726;
  color: black;
}

#RootNAmeprint {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  border: #000 solid 2px;
  border-bottom-width: 0px;
  font-size: 18px;
  color: #000;
  font-weight: 500;
  /* direction: rtl; */
  text-align: right;
  padding: 5px;
}

#studentsRoot {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 2px solid rgb(110, 107, 107);
  width: 100%;
  direction: rtl;
  font-size: 22px;
  color: #000;
  font-weight: bold;
}

#studentsRoot td, #studentsRoot th {
  border: 2px solid rgb(110, 107, 107);
  padding: 2px;
}

#SectionTable {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  border: #000 solid 2px;
  border-bottom-width: 0px;
  font-size: 22px;
  color: #000;
  font-weight: bold;
  direction: rtl;
  text-align: center;
  padding: 5px;
}

#notsAllOrder {
  display: flex;
  align-items: center;
  min-height: 60px;
  max-height: auto;
  width: 100%;
  border: #000 solid 2px;
  /* border-top-width: 0px; */
  font-size: 22px;
  color: #000;
  font-weight: 500;
  direction: rtl;
  text-align: right;
  padding: 5px;
}

#tablePrintMargin {
  margin-bottom: 100px;
}

#SliderEverGreen {
  background-color: #ffffff;
  min-height: 100vh;
  max-height: 100%;
  width: 100%;
  margin-top: 82px;
}
#loading {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color:#eeeeee;
  background-image: url(/static/media/loading.79960da1.gif);
  background-repeat: no-repeat;
  background-position: center;
}
@media print {
  body * {
    /* visibility: hidden; */
    margin-top: -8px;
    /* padding-top: 0px; */
  }
  /* #students ,#notsAllOrder,#notsAllOrder * , #students * {
      visibility: visible;
    }
  
     #students ,#notsAllOrder {
      position: relative;
      left: 0;
      top: -10px;
    } */
}

@media screen and (max-width: 768px) {
  #ContinerComponentHome {
    flex-direction: column;
  }
  #Component1Home {
    margin-right: 0px;
    margin-top: 20px;
    height: 200px;
    width: 200px;
  }
  #Component2Home {
    margin-left: 0px;
    margin-top: 20px;
    height: 200px;
    width: 200px;
  }
  #Component1Homeimg, #Component2Homeimg {
    height: 130px;
    width: 130px;
  }
}
